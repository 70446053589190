import { Component, NgZone } from '@angular/core';
import { WindowService } from './services/window/window.service';
import { PlatformService } from './services/platform/platform.service';

import { SplashScreen } from '@capacitor/splash-screen';
import { SettingsService } from './services/settings/settings.service';
import { AuthService } from './services/auth/auth.service';
import { Router } from '@angular/router';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import {
  FirebaseMessaging,
  GetTokenOptions,
} from "@capacitor-firebase/messaging";
import { ProfilService } from './services/profil/profil.service';
import { CandidateService } from './services/candidate/candidate.service';
import { ReferencesService } from './services/references/references.service';
import { NotificationsService } from './services/notifications/notifications.service';
import { SmsModalPage } from './pages/sms-modal/sms-modal.page';
import { MaintenanceModalPage } from './pages/maintenance-modal/maintenance-modal.page';
import { ModalController } from '@ionic/angular';
import { RecruiterService } from './services/recruiter/recruiter.service';
import { RemindersService } from './services/reminders/reminders.service';
import { CompanyService } from './services/company/company.service';
import { AlertController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { environment } from './../environments/environment';
import { SubscriptionService } from './services/subscription/subscription.service';
import { MenuController } from '@ionic/angular';
import { SwUpdate } from '@angular/service-worker';

import { SeoService } from './services/seo/seo.service';
import { filter, take } from 'rxjs/operators';

import { Capacitor } from "@capacitor/core";
import { initializeApp } from "firebase/app";
import { FirebaseCrashlytics } from '@capacitor-firebase/crashlytics';
import { InfosLiveService } from './services/infos-live/infos-live.service';
import { LocalStorageService } from './services/local-storage/local-storage.service';




@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  isNewUser: string = 'new';
  userRole: string | null = null;
  universalLinksLoad: boolean = false;
  getRateAppSubscribtion: Subscription | null = null;
  environment: any = null;
  selectedIndex: number | null = null;

  // PWA VAR
  appPages: Array<any> = [];
  mobileUrlPrefix: string = 'tabs/';
  update_ready: string = "Mise à jour disponible";
  update_ready_text: string = "Une mise à jour de ClubOfficine est disponible. Il est fortement conseillé de recharger la page.";
  restart_: string = "Recharger";
  later_: string = "Plus tard";
  subscriptionList: Array<any> = [];
  isMobile: boolean | null = null;
  isPlatformBrowser: boolean = false;
  hasBeenLogedOut: boolean = false;
  deeplinkRedirected: boolean = false;
  myRecruiterSubs: Subscription | null = null;
  candidateSubs: Subscription | null = null;

  constructor(
    private windowService: WindowService,
    private platformService: PlatformService,
    private swUpdate: SwUpdate,
    private settingsService: SettingsService,
    private authService: AuthService,
    private router: Router,
    private profilService: ProfilService,
    private candidateService: CandidateService,
    private referencesService: ReferencesService,
    private remindersService: RemindersService,
    private modalController: ModalController,
    private notificationsService: NotificationsService,
    private recruiterService: RecruiterService,
    private companyService: CompanyService,
    private alertController: AlertController,
    private zone: NgZone,
    private subscriptionService: SubscriptionService,
    private menuController: MenuController,
    private seoService: SeoService,
    private infosLiveService: InfosLiveService,
    private localStorageService: LocalStorageService
  ) {
    this.environment = environment;
    console.log('APP COMPONENT cst() start');
    console.log(this.environment.appVersion);
    if (windowService.isPlatformBrowser()) {
      this.isPlatformBrowser = true;
    }
    this.initializeFirebase();
    if (environment.isPwa) {
      this.initPwaMenu();
      this.seoService.initTaging();
    }
    this.initializeApp();

    if (!environment.isPwa && this.windowService.isPlatformBrowser()) {
      document.addEventListener('ionBackButton', (ev: any) => {
        ev.detail.register(10, (processNextHandler: any) => {
          console.log(this.router.url);
          if (this.router?.url?.match(/\//g) && this.router?.url?.match(/\//g)!.length > 3 && this.router.url !== '/tabs/candidat/rechercher/offres' || !this.router.url.includes('/tabs/')) {
            processNextHandler();
          } else {
          }
        });
      });
    }

    this.settingsService.getIsMobile().subscribe(isMobile => {
      this.isMobile = isMobile;
    });

  }


  public async initializeFirebase(): Promise<void> {
    if (Capacitor.isNativePlatform()) {
      return;
    }
    initializeApp(environment.firebase);
  }


  ngOnDestoy() {
    if (this.subscriptionList[0]) {
      this.subscriptionList.forEach(element => {
        element.unsubscribe();
      });
    }
  }

  /**
   * Init PWA burgermenu
   */
  initPwaMenu() {
    this.appPages = [
      {
        title: "Offres d'emploi",
        url: '/rechercher/offres',
        icon: 'briefcase'
      },
      {
        title: 'Candidats',
        url: '/rechercher/candidats',
        icon: 'people-circle'
      },
      {
        title: 'Aide',
        action: 'support',
        icon: 'information-circle'
      }
    ];
  }

  menuClick(page: any, index: number | null) {
    if (page.url || page.url === '') {
      //   if (page.url == '/landing') {
      this.closeMenu();
      //    }
      this.selectedIndex = index;
      this.router.navigate([page.url]);
    } else if (page.action == 'support') {
      this.selectedIndex = index;
      this.windowService.open("https://assistance.clubofficine.fr/hc/fr", "_blank");
    }
  }

  async closeMenu() {
    await this.menuController.close();
  }


  initializeApp() {
    this.platformService.ready().then(() => {
      console.log('APPCOMPONENT DEVICE READY');
      if (this.windowService.isPlatformBrowser()) {
        this.setUpdDeepLinks();
      }
      if (!environment.isPwa) {
        this.confirgureCrashLytics();
      } else {
        this.settingsService.checkSsoInUrlAndLogin();
        setTimeout(() => {
          this.checkForUpdates();
        }, 800);
      }
      let displayTabsSubs = this.settingsService.getDisplayTabs().subscribe((res: any) => {
        if (res) {
          this.mobileUrlPrefix = 'tabs/';
        } else {
          this.mobileUrlPrefix = '';
        }
      });
      this.subscriptionList.push(displayTabsSubs);
      let maintenanceSubs = this.settingsService.checkMaintenance().subscribe((res: any) => {
        console.log('APPCOMPONENT open maintenance');
        console.log(res);
        if (res.maintenance === true) {  // && environment.production
          this.openMaintenanceModal(res.detail, 'maintenance', false);
        }
        else if (res.displayWelcomeMessage) {
          this.openMaintenanceModal(res, 'welcomeMessage', true);
        }
      });
      this.subscriptionList.push(maintenanceSubs);
      let outdatedSubs = this.settingsService.checkOutdated().subscribe((outdatedVersion: any) => {
        console.log('APPCOMPONENT checkOutdated()');
        console.log(outdatedVersion);
        if (outdatedVersion == 'force' && !environment.isPwa) {
          console.log('APPCOMPONENT checkOutdated() force');
          this.openMaintenanceModal('outdatedVersionForce', 'outdatedVersionForce', false);
        } else if (outdatedVersion && !environment.isPwa) {
          console.log('APPCOMPONENT checkOutdated() true');
          this.openMaintenanceModal('outdatedVersion', 'outdatedVersion', true);
        }
      });
      this.subscriptionList.push(outdatedSubs);
      let previousState: any = "none";
      let authSubs = this.authService.authenticationState.pipe(filter(res => res !== null)).subscribe(state => {
        console.log('APPCOMPONENT initializeApp() before if(state)');
        console.log(state);
        if (state === false) {
          this.hasBeenLogedOut = true;
          this.unsubscribeRecruiterAndCandidate();
          console.log('APPCOMPONENT initializeApp() state = false');
        }
        if (state !== previousState) {
          if (state == true) {
            console.log('APPCOMPONENT initializeApp() state = true');
            let roleSubs = this.profilService.getuserRoleObs().subscribe(role => {
              console.log('APPCOMPONENT initializeApp() role =');
              console.log(role);
              if (role && this.userRole != role) {
                if (role && role == 'ROLE_CANDIDATE' || role == 'ROLE_RECRUITER') {
                  this.remindersService.initReminders(role);
                  this.infosLiveService.subscribeToInfosLive();
                  if (this.getRateAppSubscribtion) {
                    this.getRateAppSubscribtion.unsubscribe();
                  }
                  console.log('APPCOMPONENT initializeApp() role = candidate or recruiter');
                  let rateAppSubs = this.getRateAppSubscribtion = this.settingsService.getRateApp().subscribe(rate => {
                    console.log('APPCOMPONENT initializeApp() getRateApp()');
                    if (rate) {
                      setTimeout(() => {
                        this.openMaintenanceModal('rateApp', 'rateApp', true);
                      }, 2000);
                    }
                  });
                  this.subscriptionList.push(rateAppSubs);
                  setTimeout(() => {
                    this.referencesService.initListsFromApi(role);
                  }, 500);
                }
                if (role && role == 'ROLE_CANDIDATE') {
                  console.log('APPCOMPONENT role is candidate');
                  if (!this.profilService.getIsNew()) {
                    this.isNewUser = 'existing';
                  }
                  if (!this.universalLinksLoad) {
                    if (!this.environment.isPwa || this.router.url.includes('login')) {
                      this.router.navigate([this.mobileUrlPrefix + 'candidat']);
                    } else {
                      setTimeout(() => {
                        if (this.router.url.includes('login') || this.router.url.includes('/sso-')) {
                          this.router.navigate([this.mobileUrlPrefix + 'candidat']);
                        }
                      }, 500);
                    }
                  }
                  this.configureNotifications();
                  this.checkTunnelPosition();

                } else if (role && role == 'ROLE_RECRUITER') {
                  console.log('APPCOMPONENT role is recruiter start');
                  this.checkRecruiterDuplicate();
                  if (!this.profilService.getIsNew()) {
                    this.isNewUser = 'existing';
                  }
                  if (!this.universalLinksLoad) {
                    console.log('APPCOMPONENT role is recruiter !this.universalLinksLoad');
                  }
                  this.configureNotifications();
                  this.checkRTunnelPosition();
                } else {
                  console.log('APPCOMPONENT role is null');
                  setTimeout(() => {
                    if (!this.userRole || (this.userRole != 'ROLE_RECRUITER' && this.userRole != 'ROLE_CANDIDATE')) {
                      this.router.navigate(['login']);
                    }
                  }, 1500);
                }
              }
              this.userRole = role;
            });
            this.subscriptionList.push(roleSubs);
            this.initData();
            if (!environment.isPwa) {
              setTimeout(() => {
                console.log('Splashcreen.hide()');
                SplashScreen.hide();
              }, 600);
            }
          } else {
            console.log('APPCOMPONENT initializeApp() state = false');
            this.userRole = null;
            if (environment.isPwa) {
            } else {
              this.router.navigate(['login']);
              if (!this.platformService.is("pwa")) {
                setTimeout(() => {
                  console.log('Splashcreen.hide()');
                  SplashScreen.hide();
                }, 1800);
              }
            }
            setTimeout(() => {
              this.referencesService.initListsFromApi(null);
            }, 30);
          }
        }
        previousState = state;
      });
      this.subscriptionList.push(authSubs);
    });
  }

  handleLoginRedirect() {
    return this.localStorageService.get('redirect-after-login').then((redirect: any) => {
      if (redirect) {
        this.router.navigateByUrl(redirect, { replaceUrl: true });
        this.localStorageService.remove('redirect-after-login');
        return true;
      } else {
        return false;
      }
    });
  }

  setUpdDeepLinks() {
    console.log("APPCOMPONENT setUpdDeepLinks()");
    // Gérer le cas où l'application est déjà ouverte (écouteur d'événements)
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      console.log('APPCOMPONENT setUpdDeepLinks() App opened');
      this.zone.run(() => {
        console.log('APPCOMPONENT setUpdDeepLinks() App opened with URL:', event.url);
        this.handleDeepLink(event.url);
      });
    });

    // Gérer le cas où l'application est lancée via un lien profond à froid
    App.getLaunchUrl().then((event: any) => {
      if (event?.url) {
        console.log('APPCOMPONENT setUpdDeepLinks() App launched with URL:', event.url);
        this.zone.run(() => {
          this.handleDeepLink(event.url);
        });
      }
    });
  }

  handleDeepLink(url: string) {
    console.log('Handling deep link URL:', url);
    // Vérifie si l'url est bien une chaîne avant de l'utiliser
    if (typeof url === 'string') {
      let slug = url.split(".fr").pop();
      console.log('APPCOMPONENT setUpdDeepLinks() slug before processing:', slug);
      // Vérifie que le slug est bien défini et ne contient pas des segments non autorisés
      if (slug && slug !== undefined && !slug.includes('sso') && !slug.includes('transaction') && !this.deeplinkRedirected) {
        this.deeplinkRedirected = true;
        setTimeout(() => {
          this.deeplinkRedirected = false;
        }, 5000);
        console.log('APPCOMPONENT setUpdDeepLinks() navigateByUrl');
        // Redirection après 10 secondes vers le même slug
        if (this.platformService.is('ios')) {
          console.log('APPCOMPONENT setUpdDeepLinks() navigateByUrl slug (ios)');
          this.router.navigateByUrl(slug || '/');  // Fournir une valeur par défaut
        } else {
          setTimeout(() => {
            console.log('APPCOMPONENT setUpdDeepLinks() navigateByUrl slug (android)');
            this.router.navigateByUrl(slug || '/');  // Fournir une valeur par défaut
          }, 500);
        }
      } else {
        console.log('Le slug contient "sso" ou "transaction", aucune redirection effectuée.');
      }
    } else {
      console.log('Erreur: L\'URL fournie n\'est pas une chaîne.', url);
    }
  }






  async configureNotifications() {
    console.log('APPCOMPONENT configureNotifications() start');
    //  if (this.platformService.is('ios')) {
    FirebaseMessaging.requestPermissions().then(permission => {
      console.log('APPCOMPONENT configureNotifications() permission =');
      console.log(permission);
    }, error => {
      console.log('APPCOMPONENT configureNotifications() permission error =');
      console.log(error);
    });
    //  }

    const options: GetTokenOptions = {
      vapidKey: environment.firebase.vapidKey,
    };
    if (Capacitor.getPlatform() === "web") {
      options.serviceWorkerRegistration =
        await navigator.serviceWorker.register("firebase-messaging-sw.js");
      console.log("APPCOMPONENT configureNotifications() web notification worker launched");
    }
    const { token } = await FirebaseMessaging.getToken(options);
    this.notificationsService.checkRegistrationStatusAndRegister(token);
    console.log(`APPCOMPONENT configureNotifications() The notification token is ${token}`);

    let backgroundNotificationReceivedSubs: any = FirebaseMessaging.addListener('notificationActionPerformed', data => {
      console.log('notificationActionPerformed', { data });
      console.log(data);
      if (data.notification.title == "Nouveau message" && !this.router.url.includes('/recruteur/messages/') && !this.router.url.includes('/candidat/messagerie/')) {
        this.profilService.getuserRoleObs().pipe(
          filter(res => res !== null),
          take(1)
        ).subscribe(role => {
          console.log('Role:', role);
          if (role == 'ROLE_CANDIDATE') {
            this.router.navigateByUrl(this.mobileUrlPrefix + 'candidat/messagerie');
          } else if (role == 'ROLE_RECRUITER') {
            this.router.navigateByUrl(this.mobileUrlPrefix + 'recruteur/messages');
          }
        });
      } else if (data.notification.title == "Nouvelle proposition") {
        this.router.navigateByUrl(this.mobileUrlPrefix + 'candidat/mes-propositions');
      } else if (data.notification.title == "Nouvelle candidature") {
        this.router.navigateByUrl(this.mobileUrlPrefix + 'recruteur/candidatures');
      } else if (data.notification.title?.includes("associ") || data.notification.title?.includes("vente") || data.notification.title?.includes("installation")) {
        //  this.router.navigateByUrl(this.mobileUrlPrefix + 'transactions');
      }
    });
    this.subscriptionList.push(backgroundNotificationReceivedSubs);

    let messageReceivedSubs: any = FirebaseMessaging.addListener("notificationReceived", ((data) => {
      console.log(`FCM message:`);
      console.log(data);
      if (data.notification.title == "Nouveau message" && !this.router.url.includes('/recruteur/messages/') && !this.router.url.includes('/candidat/messagerie/')) {
        this.presentAlertConfirm(data.notification.title, data.notification.body, 'message');
      } else if (data.notification.title == "Nouvelle proposition") {
        this.presentAlertConfirm('ClubOfficine', 'Vous avez une nouvelle proposition en attente', 'proposal');
      } else if (data.notification.title == "Nouvelle candidature") {
        this.presentAlertConfirm('ClubOfficine', 'Vous avez une nouvelle candidature en attente', 'proposal');
      }
    }));
    this.subscriptionList.push(messageReceivedSubs);

    let notificationActionPerformedSubs: any = FirebaseMessaging.addListener("notificationActionPerformed", (event) => {
      console.log("notificationActionPerformed: ", { event });
    });
    this.subscriptionList.push(notificationActionPerformedSubs);


    if (Capacitor.getPlatform() === "web") {
      navigator.serviceWorker.addEventListener("message", (event: any) => {
        if (!event?.data?.notification?.title) {
          return;
        }
        console.log("serviceWorker message: ", { event });
        const notification = new Notification(event.data.notification.title, {
          body: event.data.notification.body,
        });
        notification.onclick = (event) => {
          console.log("notification clicked: ", { event });
        };
      });
    }
  }

  confirgureCrashLytics() {
    console.log('APPCOMPONENT configureCrashLytics()');
    let userUidSubs = this.profilService.getuserUidObs().pipe(filter(res => res !== null)).subscribe((res: any) => {
      console.log('APPCOMPONNENT configureCrashLytics() - getUserUidObs =');
      console.log(res);
      FirebaseCrashlytics.setUserId({ 'userId': res });
    });
    this.subscriptionList.push(userUidSubs);
  }

  initData() {
    console.log('APPCOMPONENT initData()');
    this.profilService.init();
  }

  checkTunnelPosition() {
    console.log("LANDING checkTunnelPosition()");
    let initProfilSubs = null;
    initProfilSubs = this.profilService.initAndSaveUserProfil().subscribe(() => { });
    let ssoApple: boolean = false;
    this.settingsService.getSsoApple().subscribe((sso: boolean) => {
      ssoApple = sso;
    });
    this.subscriptionList.push(initProfilSubs);
    this.candidateSubs = this.candidateService.getCandidate().pipe(filter(res => res !== null)).subscribe((res: any) => {
      console.log("LANDING checkTunnelPosition() getCandidate : res=");
      console.log(res);
      if (!ssoApple) {
        if (!res || !res.qualification || !res.firstName || !res.lastName) {
          this.router.navigate(['tunel/candidat/step1/' + this.isNewUser]);
        } else if ((!res.wishes || !res?.wishes?.companyCategories?.[0] || !res?.wishes?.searchAddresses?.[0]) && res.jobListening != false) {
          this.router.navigate(['tunel/candidat/step3/' + this.isNewUser]);
        }
        if (res && res.qualification && !res.isPhoneValidated && environment.production) {  //   re-add after test
          console.log('LANDING openSmsModal()');
          this.openSmsModal(res);
        }
        this.handleLoginRedirect();
      }
    }, error => {
      if (error.status == 404) {
        this.candidateService.postCandidate().pipe(take(1)).subscribe(newCandidate => {
          console.log('CANDIDATESERVICE getCandidate() 404 error -> candidat created');
          this.router.navigate(['tunel/candidat/step1/' + this.isNewUser]);
        });
      }
    });
    this.subscriptionList.push(this.candidateSubs);
  }

  checkRecruiterDuplicate() {
    console.log('APPCOMPONENT checkRecruiterDuplicate() start');
    console.log(this.hasBeenLogedOut);
    if (!this.hasBeenLogedOut) {  // || this.router.url.includes('al/')
      return;
    }
    this.recruiterService.checkDuplicates().subscribe((duplicate: any) => {
      console.log('APPCOMPONENT checkRecruiterDuplicate() res');
      console.log(duplicate);
      if (duplicate?.['hydra:member']?.length > 0) {
        let myRecruiterSubs = this.recruiterService.getMyRecruiterProfilObs().pipe(filter(res2 => res2 != null), take(1)).subscribe((recruiter: any) => {
          if (!this.recruiterService.getIsMerging()) {
            this.openSmsModal(recruiter, duplicate);
          }
        });
        this.subscriptionList.push(myRecruiterSubs);
      }
    });
  }

  unsubscribeRecruiterAndCandidate() {
    if (this.candidateSubs) {
      this.candidateSubs.unsubscribe();
    }
    if (this.myRecruiterSubs) {
      this.myRecruiterSubs.unsubscribe();
    }
  }

  checkRTunnelPosition() {
    console.log("LANDING checkRTunnelPosition()");
    let initProfilSubs = null;
    initProfilSubs = this.profilService.initAndSaveUserProfil().subscribe(() => { });
    this.subscriptionList.push(initProfilSubs);
    this.recruiterService.initMyRecruiterProfil();
    this.myRecruiterSubs = this.recruiterService.getMyRecruiterProfilObs().pipe(filter(res2 => res2 != null)).subscribe((res: any) => {
      console.log("LANDING checkRTunnelPosition() getMyRecruiterProfilObs : res=");
      console.log(JSON.parse(JSON.stringify(this.router.url)));
      console.log(res);
      if (res?.companies?.[0] && res.lastname && res?.firstname && res?.phone?.number) {
        console.log("LANDING checkRTunnelPosition() recruteur has company");
        this.companyService.initMyCompanies(res.companies);
        if (!this.universalLinksLoad) {
          console.log('checkRTunnel go to candidatures');
          if (this.router.url.length < 2 || this.router.url.includes("/candidat/") || this.router.url.includes("/login") || this.router.url.includes("/sso-") || (!this.environment.isPwa && !this.router.url.includes('/recruteur/'))) {
            console.log('checkRTunnel go to candidatures true');
            this.router.navigateByUrl(this.mobileUrlPrefix + 'recruteur/candidatures');
          }
        }
        this.handleLoginRedirect();
      } else if (!res.companies?.[0] || !res.lastname || !res?.firstname || !res?.phone?.number) {
        console.log("recruiter checkRTunnel() go to tunnel");
        this.router.navigate(['r-tunel/' + this.isNewUser]);
      }
      let fullSubscriptionSubs = this.subscriptionService.getFullSubscriptionData().subscribe((res: any) => { });
      this.subscriptionList.push(fullSubscriptionSubs);
      let orderAlertsSubs = this.subscriptionService.getOrdersAlerts().subscribe((res: any) => { });
      this.subscriptionList.push(orderAlertsSubs);
    });
    this.subscriptionList.push(this.myRecruiterSubs);
  }

  /**
  * Open modal page when in maintenance
  */
  async openMaintenanceModal(detail: any, type: string | null = null, canDismiss: boolean = false, info: any = null) {
    console.log('TUNEL openMaintenanceModal()');
    let breakpoints: Array<number> | undefined;
    let initialBreakpoint: number | undefined;
    if (this.isMobile) {
      breakpoints = [0, 0.35, 0.5, 0.8, 1];
      initialBreakpoint = 0.8;
    }
    let dismissOptions: any = (data?: any, role?: string) => {
      return new Promise<boolean>(resolve => resolve(role === "close"))
    };
    if (canDismiss === true) {
      dismissOptions = true;
    }
    const modal = await this.modalController.create({
      component: MaintenanceModalPage,
      canDismiss: dismissOptions,
      backdropDismiss: true,
      componentProps: {
        "detail": detail,
        "type": type,
        "info": info
      }
    });

    modal.onDidDismiss().then((dataReturned: any) => {
      console.log('TUNEL openMaintenanceModal()');
      console.log(dataReturned);
      if (dataReturned.data) {
        console.log('DASHBOARD openMaintenanceModal() res retourned');
      }
    });
    return await modal.present();
  }


  /**
  * Open modal page to enter sms validation code
  * @param {Object} the diplome image to display
  */
  async openSmsModal(userProfil: any, duplicate: any = null) {
    console.log('TUNEL openSmsModal()');
    const modal = await this.modalController.create({
      component: SmsModalPage,
      canDismiss: (data?: any, role?: string) => {
        return new Promise<boolean>(resolve => resolve(role === "close"))
      },
      backdropDismiss: false,
      componentProps: {
        "userRole": this.userRole,
        "userProfil": userProfil,
        "isMobile": this.isMobile,
        "duplicate": duplicate
      }
    });

    modal.onDidDismiss().then((dataReturned: any) => {
      console.log('TUNEL openSmsModal()');
      console.log(dataReturned);
      if (dataReturned.data) {
        console.log('DASHBOARD openSmsModal() res retourned');
      }
    });
    return await modal.present();
  }

  /**
    * Display alert confirmation
    * @param {string} msg message to display
    */
  async presentAlertConfirm(title: string = "", message: string = "", type: string) {
    const alert = await this.alertController.create({
      header: title,
      message: message,
      buttons: [
        {
          text: 'Annuler',
          role: 'cancel',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        },
        {
          text: 'Voir',
          handler: () => {
            console.log('Confirm Okay');
            if (type == "message" && !this.router.url.includes('/recruteur/messages') && !this.router.url.includes('/candidat/messagerie')) {
              if (this.userRole == 'ROLE_CANDIDATE') {
                this.router.navigateByUrl(this.mobileUrlPrefix + 'candidat/messagerie');
              } else {
                this.router.navigateByUrl(this.mobileUrlPrefix + 'recruteur/messages');
              }
            } else if (type == "proposal") {
              if (this.userRole == 'ROLE_CANDIDATE') {
                this.router.navigateByUrl(this.mobileUrlPrefix + 'candidat/mes-propositions');
              } else {
                this.router.navigateByUrl(this.mobileUrlPrefix + 'recruteur/candidatures');
              }
            }
          }
        }
      ]
    });
    await alert.present();
  }


  checkForUpdates() {
    if (!this.swUpdate.isEnabled) {
    } else {
      let swUpdateSubs = this.swUpdate.available.subscribe(event => {
        this.swUpdate.activateUpdate().then(() => {
          this.presentAlertRestart();
        });
      });
      this.subscriptionList.push(swUpdateSubs);
      this.swUpdate.checkForUpdate();
      if (this.windowService.isPlatformBrowser()) {
        setTimeout(() => {
          this.swUpdate.checkForUpdate().then((res) => {
          });
        }, 2000);
      }
    }
  }

  updateApp() {
    document.location.reload();
  }

  async presentAlertRestart() {
    const alert = await this.alertController.create({
      header: this.update_ready,
      message: this.update_ready_text,
      buttons: [
        {
          text: this.later_,
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
          }
        }, {
          text: this.restart_,
          handler: () => {
            this.updateApp();
          }
        }
      ]
    });
    await alert.present();
  }

}